<template>
    <div class="community-news-block">
        <div class="container">
            <w-breadcrumb :items="breadcrumb"></w-breadcrumb>
            <div class="head">
                <div class="title">
                    {{ $i18n.get(`News`) }}
                </div>

                <div v-if="$store.state.isMobile" class="mobile-nav">
                    <w-button type="border" size="xs" @click="openMobileMenu()" :class="buttonClasses">
                        <svg v-if="activeButton" width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.92789 18.9529C9.39789 18.9549 8.88789 18.7429 8.51389 18.3669L0.676889 10.5319C0.472562 10.3282 0.314882 10.0826 0.214743 9.81209C0.114605 9.54154 0.0743937 9.25248 0.0968889 8.96487L0.596889 2.39887C0.631432 1.92085 0.837416 1.47138 1.17695 1.13313C1.51649 0.794881 1.96674 0.590604 2.44489 0.557875L9.01089 0.0578749C9.06289 0.0468749 9.11389 0.046875 9.16589 0.046875C9.69589 0.046875 10.2029 0.256875 10.5759 0.632875L18.4139 8.46688C18.5997 8.6526 18.7471 8.87312 18.8476 9.11582C18.9482 9.35853 19 9.61866 19 9.88137C19 10.1441 18.9482 10.4042 18.8476 10.6469C18.7471 10.8896 18.5997 11.1101 18.4139 11.2959L11.3419 18.3669C11.1566 18.5532 10.9363 18.7009 10.6936 18.8015C10.4509 18.9021 10.1906 18.9535 9.92789 18.9529ZM5.68489 3.63887C5.356 3.63897 5.03221 3.72017 4.74219 3.87529C4.45218 4.0304 4.20489 4.25465 4.02224 4.52815C3.83959 4.80166 3.72721 5.116 3.69505 5.44331C3.66289 5.77062 3.71194 6.10082 3.83787 6.40465C3.9638 6.70847 4.16271 6.97656 4.41699 7.18515C4.67127 7.39375 4.97306 7.53641 5.29564 7.60052C5.61823 7.66462 5.95164 7.64818 6.26635 7.55266C6.58107 7.45713 6.86736 7.28547 7.09989 7.05287L7.10689 7.04688L7.11389 7.03988L7.10589 7.04688C7.38388 6.76644 7.57263 6.40999 7.64836 6.02246C7.72409 5.63492 7.68341 5.23364 7.53145 4.86918C7.37949 4.50473 7.12304 4.19341 6.79443 3.97447C6.46582 3.75553 6.07976 3.63876 5.68489 3.63887Z" fill="url(#paint0_linear)"/>
                            <path d="M13.1464 11.3536L13.5 11.7071L13.8536 11.3536L19.3536 5.85355L19.7071 5.5L19.3536 5.14645L17.3536 3.14645L17 2.79289L16.6464 3.14645L13.5 6.29289L12.3536 5.14645L12 4.79289L11.6464 5.14645L9.64645 7.14645L9.29289 7.5L9.64645 7.85355L13.1464 11.3536Z" fill="#FFB633" stroke="#F2F5F9"/>
                            <defs>
                                <linearGradient id="paint0_linear" x1="4.52265" y1="-5.24681" x2="22.8331" y2="16.7077" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFD300"/>
                                    <stop offset="1" stop-color="#FF9669"/>
                                </linearGradient>
                            </defs>
                        </svg>

                        <svg v-else width="21" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.92789 18.9529C9.39789 18.9549 8.88789 18.7429 8.51389 18.3669L0.676889 10.5319C0.472562 10.3282 0.314882 10.0826 0.214743 9.81209C0.114605 9.54154 0.0743937 9.25248 0.0968889 8.96487L0.596889 2.39887C0.631432 1.92085 0.837416 1.47138 1.17695 1.13313C1.51649 0.794881 1.96674 0.590604 2.44489 0.557875L9.01089 0.0578749C9.06289 0.0468749 9.11389 0.046875 9.16589 0.046875C9.69589 0.046875 10.2029 0.256875 10.5759 0.632875L18.4139 8.46688C18.5997 8.6526 18.7471 8.87312 18.8476 9.11582C18.9482 9.35853 19 9.61866 19 9.88137C19 10.1441 18.9482 10.4042 18.8476 10.6469C18.7471 10.8896 18.5997 11.1101 18.4139 11.2959L11.3419 18.3669C11.1566 18.5532 10.9363 18.7009 10.6936 18.8015C10.4509 18.9021 10.1906 18.9535 9.92789 18.9529ZM5.68489 3.63887C5.356 3.63897 5.03221 3.72017 4.74219 3.87529C4.45218 4.0304 4.20489 4.25465 4.02224 4.52815C3.83959 4.80166 3.72721 5.116 3.69505 5.44331C3.66289 5.77062 3.71194 6.10082 3.83787 6.40465C3.9638 6.70847 4.16271 6.97656 4.41699 7.18515C4.67127 7.39375 4.97306 7.53641 5.29564 7.60052C5.61823 7.66462 5.95164 7.64818 6.26635 7.55266C6.58107 7.45713 6.86736 7.28547 7.09989 7.05287L7.10689 7.04688L7.11389 7.03988L7.10589 7.04688C7.38388 6.76644 7.57263 6.40999 7.64836 6.02246C7.72409 5.63492 7.68341 5.23364 7.53145 4.86918C7.37949 4.50473 7.12304 4.19341 6.79443 3.97447C6.46582 3.75553 6.07976 3.63876 5.68489 3.63887Z" fill="#001A54"/>
                        </svg>

                        {{ $i18n.get(`Tags`) }}
                    </w-button>

                    <transition name="mobile-menu">
                        <div class="mobile-tags-menu" v-if="mobileMenuIsOpen">
                            <w-checkbox v-for="(tag, index) of tags"
                                v-model="tag.checked"
                                @change="updateNews"
                                :style="{color: tag.color}"
                                :key="index">

                                #{{ tag.title }}
                            </w-checkbox>
                        </div>
                    </transition>
                </div>

                <div v-else class="desktop-nav">
                    <w-tag
                        v-for="(tag, index) of tags"
                        :tag="tag"
                        @tags-changed="updateNews"
                        :key="index"
                    >
                    </w-tag>
                </div>
            </div>
            <div class="content" v-infinite-scroll="loadMore"
                :infinite-scroll-disabled="isLoading || !isLoadMore"
                infinite-scroll-distance="10">

                <news-item v-for="(item, index) of news"
                    :news="item"
                    :key="index">
                </news-item>

                <template v-if="news.length < 1">
                    <news-item-preloader v-for="item of 6" :key="item"></news-item-preloader>
                </template>
            </div>

            <w-loader v-if="isLoading"></w-loader>
        </div>

        <div class="overlay" @click="closeMobileMenu" v-if="mobileMenuIsOpen && this.$store.state.isMobile"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewsItem from '../snippets/NewsItem.vue'
import NewsItemPreloader from './NewsItemPreloader.vue'

export default {
    name: 'news-block',
    components: {
        'news-item': NewsItem,
        'news-item-preloader': NewsItemPreloader
    },
    data() {
        return {
            predefinedTags: [],
            mobileMenuIsOpen: false,
            breadcrumb: [
                {
                    title: 'Community',
                    link: '/community'
                },
                {
                    title: 'News'
                }
            ]
        }
    },
    computed: {
        ...mapGetters('news', {
            tags: 'getTags',
            news: 'getCommunityNews',
            isLoadMore: 'isLoadMore',
            isLoading: 'isLoading',
        }),
        buttonClasses() {
            return {
                '-active': this.mobileMenuIsOpen
            }
        },
        activeButton() {
            return this.tags.filter(tag => tag.checked === true).length > 0
        },
    },
    methods: {
        ...mapActions('news', {
            loadNews: 'loadNews',
            loadTags: 'loadTags',
        }),
        loadMore() {
            if (this.isLoading) {
                return
            }

            if (this.isLoadMore) {
                this.$store.commit('news/nextPage')
                this.loadNews()
            }
        },
        openMobileMenu() {
            this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
        },
        closeMobileMenu() {
            this.mobileMenuIsOpen = false
            this.$functions.unlockBody()
        },
        updateNews() {
            this.$store.commit('news/resetPage')
            this.$store.commit('news/clearNews')

            this.loadMore()
        },
        activateTags(tagTitles) {
            this.$store.commit('news/changeTagsCheckedProp', {tagTitles, checked: true})
        }
    },
    async created() {
        this.$store.commit('news/resetPage')
        this.$store.commit('news/clearNews')

        await this.loadTags()
        if(this.predefinedTags.length) this.activateTags(this.predefinedTags)

        if (this.isLoadMore) {
            this.loadMore()
        }
    },
    beforeDestroy() {
        this.$store.commit('news/resetTags')
    }
}
</script>

<style lang="less" scoped>

.mobile-tags-menu {
    .checkbox-group {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.community-news-block {
    background-color: var(--main-grey);
    padding: 88px 0px 92px;
    > .container {
        max-width: var(--main-width-full);
        margin: 0 auto;

        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            flex-wrap: wrap;

            .title {
                font-weight: bold;
                font-size: 36px;
                line-height: 144%;
                color: var(--dark-blue);
                margin-top: 5px;
            }

            .desktop-nav {
                display: flex;
                column-gap: 5px;

            }

            .mobile-nav {
                display: none;

                > .btn {
                    column-gap: 5px;
                    padding: 8px 15px;
                }

                .mobile-tags-menu {
                    min-width: 190px;
                    right: 0px;
                    top: 40px;
                    position: absolute;
                    padding: 26px;
                    background: var(--white);
                    box-shadow: 0px 30px 50px var(--dark-blue-20);
                    border-radius: 20px;
                    z-index: 10;
                    display: flex;
                    flex-direction: column;
                    row-gap: 12px;
                }
            }
        }
        .content {
            display: flex;
            flex-wrap: wrap;
            column-gap: 18px;
            row-gap: 26px;
            margin-top: 26px;
            margin-bottom: 26px;
        }
    }
}

@media (max-width: 1023px) {
    .community-news-block {
        padding: 50px 15px 55px;

        > .container {
            max-width: var(--main-width);
            margin: 0 auto;

            .head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-weight: bold;
                    font-size: 36px;
                    line-height: 144%;
                    color: var(--dark-blue);
                }

                .desktop-nav {
                    display: flex;
                    column-gap: 3px;
                }

                .mobile-nav {
                    display: none;
                }
            }

            .content {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                column-gap: 16px;
                row-gap: 26px;
                margin-top: 26px;
            }
        }
    }
}

@media (max-width: 767px) {
    .community-news-block {
        padding: 30px 15px 34px;

        > .container {
            max-width: var(--main-width);
            margin: 0 auto;

            .head {

                .title {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 144%;
                    color: var(--dark-blue);
                }

                .desktop-nav {
                    display: none;
                }

                .mobile-nav {
                    display: block;
                    .btn {
                        &.-active {
                            border: 1px solid #001A54;
                        }
                    }
                }
            }

            .content {
                margin-top: 16px;
                margin-bottom: 16px;
            }
        }
    }
}
</style>
